import { lazy, Suspense, useContext, useEffect } from "react";
import {
  Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { AppContext, AppContextProvider } from './data/state';
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./theme/variables.css";

import Loading from "./components/Loading/Loading";
import MyNavbar from "./components/MyNavbar/MyNavbar";
import MyFooter from "./components/MyFooter/MyFooter";
import CreatePrintStatement from "./views/CreatePrintStatement/CreatePrintStatement";
import CreatePrice from "./views/CreatePrice/CreatePrice";
import Calendar from "./views/Calendar/Calendar";
import ScheduleService from "./views/ScheduleService/ScheduleService";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
import { useMobile } from "./hooks/useMobile";
import server from "./api/server";
import { User } from "./Models/User";
//import { SCHEDULE_TOKEN } from "./env";
import { registerLicense } from '@syncfusion/ej2-base';

const hist = createBrowserHistory();
const Reviews = lazy(() => import('./views/Reviews/Reviews'));
const Services = lazy(() => import('./views/Services/Services'));
const ListUser = lazy(() => import('./views/ListUser/ListUser'));
const SignInView = lazy(() => import('./views/SignInView/SignInView'));
const Home = lazy(() => import('./views/HomePage/HomePage'));
const ListPhotos = lazy(() => import('./views/ListPhotos/ListPhotos'));
const ListReview = lazy(() => import('./views/ListReview/ListReview'));
const ListTicket = lazy(() => import('./views/ListTicket/ListTicket'));
const CreateUsers = lazy(() => import('./views/CreateUsers/CreateUsers'));
const CreateEvent = lazy(() => import('./views/CreateEvent/CreateEvent'));
const ScheduleList = lazy(() => import('./views/ScheduleList/ScheduleList'));
const CreatePhotos = lazy(() => import('./views/CreatePhotos/CreatePhotos'));
const CreateReview = lazy(() => import('./views/CreateReview/CreateReview'));
const CreateSchedule = lazy(() => import('./views/CreateSchedule/CreateSchedule'));
const ListPaymentReq = lazy(() => import('./views/ListPaymentReq/ListPaymentReq'));
const CreatePaymentReq = lazy(() => import('./views/CreatePaymentReq/CreatePaymentReq'));
const EditTicket = lazy(() => import('./views/EditTicket/EditTicket'));
const ListLocation = lazy(()=> import('./views/ListLocation/ListLocation'));
const LocationZip = lazy(()=> import('./views/LocationsZip/LocationsZip'));
const Price = lazy(() => import("./views/Price/Price"));
const UpdateStatus = lazy(() => import("./views/UpdateStatus/UpdateStatus"));
const DetailTicket = lazy(() => import("./views/DetailTicket/DetailTicket"));
const DetailEvent = lazy(() => import("./views/DetailEvent/DetailEvent"));
const OpenCharge = lazy(() => import("./views/OpenCharge/OpenCharge"));
const RecoverPassword = lazy(() => import("./views/RecoverPassword/RecoverPassword"));
const ResetPassword = lazy(() => import("./views/ResetPassword/ResetPassword"));


const UpKeepApp = () => {
  const { state, dispatch } = useContext(AppContext);
  const mobile = useMobile();
  //registerLicense(SCHEDULE_TOKEN);

  useEffect(() => {
    if(state.token != undefined) {
      checkToken();
    }
  });

  const checkToken = async () => {
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${state.token}`,
      };

      try {
        const response = await server.post(
          `/company/auth/validate-token`,
          "",
          { headers: headers }
        );

        let data = response.data;

        if(data.statusCode == 200) {
          return;
        }
      } catch (error: any) {
        if (error.response?.status == 401) {
            hist.push("/home");
            window.location.reload();

            const tempUser: User ={
              name: "",
              email: "",
              phoneNumber: "",
              address: "",
            }
            await dispatch({
              type: 'set-is-loggedin',
              isLoggedin: false
            });
            await dispatch({
              type: 'set-user',
              user: tempUser
            });
            await dispatch({
              type: 'set-token',
              user: undefined
            });
            await dispatch({
              type: 'set-exist-ticket-id',
              existTicketId: false
            });
            await dispatch({
              type: 'set-ticket-id',
              ticketID: undefined
            });
            await dispatch({
              type: 'set-permissions',
              permissions: [],
            });
        }
      }
    }

  return (
    <div className="wrapper">
      <Router history={hist}>
        <Suspense fallback={<Loading />}>
          {
            state.isLoggedin &&
            <MyNavbar />
          }
          {
            state.isLoggedin? (
                !state.isTempPassword && state.isTempPassword !== undefined ? (
                  <Switch>
                    <Route path="/login"  component={SignInView} />
                    <Route path="/home" exact component={Home} />
                    //Tickets permissions id 1
                    <ProtectedRoutes permissions={state.permissions} pathGroup={1} path="/open-charge" component={OpenCharge} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={1} path="/detail-ticket" exact component={DetailTicket} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={1} path="/detail-event" exact component={DetailEvent} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={1} path="/list-tickets" component={ListTicket} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={1} path="/update-ticket" component={EditTicket} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={1} path="/create-review" component={CreateReview} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={1} path="/update-status" component={UpdateStatus} />
                    //Services permissions id 2
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/services" component={Services} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/list-photos" component={ListPhotos} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/upload-photo" component={CreatePhotos} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/schedule" exact component={ScheduleList} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/schedule-a-service" exact component={ScheduleService} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/create-schedule" component={CreateSchedule} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/create-event" component={CreateEvent} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/price" component={Price} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/create-price" component={CreatePrice} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={2} path="/calendar" component={Calendar} />
                    //Balance permissions id 3
                    <ProtectedRoutes permissions={state.permissions} pathGroup={3} path="/list-payment-request" component={ListPaymentReq} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={3} path="/create-payment-request" component={CreatePaymentReq} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={3} path="/create-print-statement" component={CreatePrintStatement}/>
                    //Locations permissions id 4
                    <ProtectedRoutes permissions={state.permissions} pathGroup={4} path="/list-location" component={ListLocation} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={4} path="/locations-zip" component={LocationZip} />
                    //Users permissions id 5
                    <ProtectedRoutes permissions={state.permissions} pathGroup={5} path="/list-user" component={ListUser} />
                    <ProtectedRoutes permissions={state.permissions} pathGroup={5} path="/create-user" component={CreateUsers} />

                    <Route path="/list-review" component={ListReview} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/" render={() => <Redirect to="/home" />} exact={true} />
                    <Redirect from="*" to="/" />
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/reset-password" component={ResetPassword} />
                    <Redirect from="*" to="/reset-password" />
                  </Switch>
                )
            ) : (
              <Switch>
                <Route path="/login" exact component={SignInView} />
                <Route path="/recover-password" component={RecoverPassword} />
                <Redirect from="*" to="/login" />
              </Switch>
            )}
          {
            state.isLoggedin &&
            <MyFooter />
          }
        </Suspense>
      </Router>
    </div>
  );
};

const App = () => {

  return (
    <AppContextProvider>
      <UpKeepApp />
    </AppContextProvider>
  )
}
export default App;
