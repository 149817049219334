import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../data/state";
import MySectionSignUp from "../MySectionSignUp/MySectionSignUp";
import "./MyFooter.css";

const Footer = () => {
  const { state, dispatch } = useContext(AppContext);

  const fooItems = [
    {
      id: 1,
      title: 'Tickets',
      locationHref: '/list-tickets'
    },
    {
      id: 2,
      title: 'Services',
      locationHref: '/services'
    },
    {
      id: 3,
      title: 'Balance',
      locationHref: '/list-payment-request'
    },
  ];

  return (
    <>
      {!state.isLoggedin && <MySectionSignUp />}
      <footer className="my-footer">
        <div className="container-footer">
          <img src="assets/logo_white.png" alt="logo" />
          <ul className="nav-footer">
            {
              state.permissions !== undefined &&
              fooItems.map((element, index: number) => {
                if(state.permissions.includes(element.id)) {
                  return (
                    <li key={index}>
                      <Link to={element.locationHref} onClick={() => window.scrollTo(0, 0)}>
                        {element.title}
                      </Link>
                    </li>
                  )
                }
              })
            }
          </ul>
          <span className="copyright-text">
            Copyright &copy; 2022 UpKeepFilm. All rights reserved
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
