import { Input } from 'reactstrap';
import "./CreateScheduleService.css";
import moment from "moment";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
interface CreateScheduleServiceItemProps {
  id: number,
  description: string,
  price: string,
  duration: string,
  removeService: any
  onChangeDescription: any,
  onChangePrice: any,
  onChangeServiceDuration: any,
  allDurations: any[],
  disabled: boolean,
};


const CreateScheduleService: React.FC<CreateScheduleServiceItemProps> = ({
                                                            id,
                                                            description = '',
                                                            price = '',
                                                            duration = '',
                                                            removeService,
                                                            onChangeDescription,
                                                            onChangePrice,
                                                            onChangeServiceDuration,
                                                            allDurations,
                                                            disabled}) => {


  const formatTime = (date: any) => moment(date, "HH:mm").format("HH:mm");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <div className="button-remove-container">
        <button
          className="remove-open-charge-button"
          onClick={removeService}
          disabled={disabled}
        >
        REMOVE
        </button>
      </div>
      <div className="create-open-charge">
        <div className="description-container">
          <p className="text-description">Description:</p>
          <Input
            type="textarea"
            className="text-area-description"
            id="exampleText"
            value={description}
            onChange={onChangeDescription}
            disabled={disabled}
          />
        </div>
        <div className="price-duration-container">
          <div className="container-info-charges">
            <p className="text-description">Price:</p>
            <input
              type="number"
              placeholder="Price"
              className="open-charge-input"
              value={price}
              onChange={onChangePrice}
              min="0"
              disabled={disabled}
            />
          </div>
          <div className="container-info-charges">
            <p className="text-description">Duration:</p>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} disabled={disabled} direction="down">
              <DropdownToggle caret className="dropdown-schedule-service">{duration}</DropdownToggle>
              <DropdownMenu>
                {allDurations.map((item: any, index: number) => {
                  return(
                    <DropdownItem
                      onClick={() => onChangeServiceDuration(item.duration)}
                      key={index}
                    >
                      {item.duration}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateScheduleService;
