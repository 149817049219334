import React, { useState, useEffect, useContext } from "react";
import "./ScheduleService.css";
import { useLocation, useHistory } from "react-router-dom";
import { AppContext } from '../../data/state';
import Loading from "../../components/Loading/Loading";
import MyTitle from "../../components/MyTitle/MyTitle";
import BtnAddList from "../../components/BtnAddList/BtnAddList";
import CreateScheduleService from "../../components/CreateScheduleService/CreateScheduleService";
import DatePicker from "react-datepicker";
import server from "../../api/server";
import moment from "moment";
import {
  Form, FormGroup, Input, Button, CustomInput, Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { UPKEEP_DOMAIN } from "../../env";

const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");
const formatTime = (date: any) => moment(date, "HH:mm").format("HH:mm:ss");

interface newScheduleService {
  description: string,
  price: string,
  duration: string,
};

const ScheduleService: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);

  const [viewTitle, setViewTitle] = useState<string>("Schedule a Service");

  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [scheduleTime, setScheduleTime] = useState<string>("");

  const [ticketId, setTicketId] = useState(-1);
  const [duration, setDuration] = useState<string>("");
  const [serviceId, setServiceId] = useState(0);
  const [companyServiceId, setCompanyServiceId] = useState(0);
  const [scheduleId, setScheduleId] = useState(0);
  const [canSubmit, setCanSubmit] = useState(false);

  const [allTimes, setAllTimes] = useState<any[]>([]);
  const [allDurations, setAllDurations] = useState<any[]>([]);

  const [modal, setModal] = useState(false);
  const [modalBody, setModalBody] = useState<string>("");
  const [modalTitle, setModalTitle] = useState<string>("");

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [addres, setAddress] = useState<string>("");
  const [phone, setPhone] = useState(0);
  const [zipCode, setZipCode] = useState(0);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);
  const [selectedTime, setSelectedTime] = useState<string>("Select time");

  const [generetedUrl, setGeneretedUrl] = useState<string>("");
  const [buttonCopiedText, setButtonCopiedText] = useState<string>("Copy");

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isUpdateTicket, setIsUpdateTicket] = useState<boolean>(false);

  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Schedule a service.",
      modalBody: "Service was scheduled.",
    },
    {
      modalTitle: "Schedule a service.",
      modalBody: "Can't schedule a service.",
    },
    {
      modalTitle: "Invalid Duration.",
      modalBody: "The maximum duration is 4 hours.",
    },
  ];
  const [servicesList, setServicesList] = useState<newScheduleService[]>([]);

  const location: any = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.isUpdate) {
        setViewTitle("Update Scheduled Service");
        findTicket(location.state.ticketId);
        setTicketId(location.state.ticketId);
        setIsUpdate(location.state.isUpdate);
        setIsUpdateTicket(location.state.isUpdate);
      } else {
        setServiceId(location.state.companyServiceID);
        findCompanyServiceId(location.state.companyServiceID, location.state.serviceDate);
        setScheduleDate(location.state.serviceDate);
      }
    }
    requestDurations();
  }, []);

  const checkIfHasTime = (serviceDate: any, allTimes: any) => {
    let serviceTime = formatTime(serviceDate);
    setScheduleTime(serviceTime);

    if (serviceTime != '00:00:00') {
      let itemTime = allTimes.find((item: any) => item.startTime == serviceTime);

      if (itemTime !== undefined) {
        setScheduleId(itemTime.id);
        setSelectedTime(itemTime.startTime);
      }
    }
  };

  const findCompanyServiceId = async (id: any, date: any) => {
    setIsLoading(true);

    const headers = {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${state.token}`
    };

    try {
      const response = await server.get(
        "/company/company-services/company",
        { headers }
      );

      for (var i = 0; i < response.data.data.length; i++) {
        if (response.data.data[i].servicesID == id) {
          setCompanyServiceId(response.data.data[i].id);
          requestTimes(response.data.data[i].id, date);
          break;
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const requestTimes = async (id: any, dateSelected: any) => {
    setIsLoading(true);

    var min = 0, hrs = 0;
    //using time formart hh:mm:ss -> "12:59"00"
    for (var i = 0; i < servicesList.length; i++) {
      //get hours from string
      hrs += parseInt(servicesList[i].duration.substring(0, 2));
      //get minutes from string
      min += parseInt(servicesList[i].duration.substring(3, 5));
    }
    hrs += Math.floor(min / 60); //get carry from minutes sumatory
    min %= 60;//truncate minutes between  0-59

    //max duration is 4 hours or 240 min
    if ((hrs * 60 + min) > 240) {
      setIsLoading(false);
      setModalBody(modalText[2].modalBody);
      setModalTitle(modalText[2].modalTitle);
      setModal(true);
      setCanSubmit(false);
      return;
    }
    //toLocaleString convert a digit 5 to 05
    var durationSumatory = hrs.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ":"
      + min.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

    let data = {
      CompanyServiceID: id,
      Date: formatDate(dateSelected),
      Duration: durationSumatory,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await server.post(
        "/customer/company-calendar/schedules",
        JSON.stringify(data),
        { headers }
      );

      setAllTimes(response.data.data);
      if (response.data.data.length == 0) {
        setCanSubmit(false);
        setSelectedTime("Select time");
      }

      if (!isUpdateTicket) {
        checkIfHasTime(dateSelected, response.data.data);
      }
      setIsLoading(false);
    } catch (error: any) {
      console.error(error.response);
      setIsLoading(false);
    }
  };

  const requestDurations = async () => {
    setIsLoading(true);

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await server.get(
        "/customer/time-units",
        { headers }
      );

      setIsLoading(false);
      setAllDurations(response.data.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const addService = () => {
    setServicesList([...servicesList, {
      description: '',
      price: '',
      duration: '00:00:00',
    }]);

    setScheduleId(0);
  };

  const removeService = (index) => {
    const values = [...servicesList];
    values.splice(index, 1);
    setServicesList(values);
  };

  const handleChangeDescription = (pos, event) => {
    const newInputFields = servicesList.map((i, index) => {
      if (pos === index) {
        i.description = event.target.value
      }
      return i;
    });

    setServicesList(newInputFields);
  };

  const handleChangePrice = (pos, event) => {
    const newInputFields = servicesList.map((i, index) => {
      if (pos === index) {
        i.price = event.target.value
      }
      return i;
    })

    setServicesList(newInputFields);
  };

  const handleScheduledServiceOnChangeduration = (pos, event) => {
    const newInputFields = servicesList.map((i, index) => {
      if (pos === index) {
        i.duration = event
      }
      return i;
    });

    setServicesList(newInputFields);
    setScheduleId(0);
  };

  const onChangeSelectTime = (e: any) => {
    setSelectedTime(e.startTime);
    setScheduleId(e.id);
    setCanSubmit(true);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`
    };

    let data = {
      Name: name,
      PhoneNumber: phone,
      Email: email,
      ZipCode: zipCode,
      Address: value,
      CompanyServiceID: companyServiceId,
      ScheduleID: scheduleId, //Optional
      NewDate: formatDate(scheduleDate), //Optional
      OpenCharges: servicesList
    };

    if (isUpdateTicket) {
      let dataUpdate = {
        Name: name,
        PhoneNumber: phone,
        Email: email,
        ZipCode: zipCode,
        Address: value,
        ScheduleID: scheduleId, //Optional
        NewDate: formatDate(scheduleDate), //Optional
        OpenCharges: servicesList
      };
      updateScheduleService(dataUpdate, headers);
    } else {
      createScheduleService(data, headers);
    }

    setIsLoading(false);
  };

  const createScheduleService = async (data: any, headers: any) => {
    try {
      const responseServer = await server.post(
        "/company/open-charges/create-service",
        JSON.stringify(data),
        { headers: headers },
      );

      if (responseServer.data.statusCode !== 200) {
        return;
      }

      setModalTitle(modalText[0].modalBody);
      const strLink = `${UPKEEP_DOMAIN}/ScheduledTicket/${responseServer.data.data.id}/${responseServer.data.data.ticketGUID}`;
      setGeneretedUrl(strLink);
      setModalBody(`You have schedule ticket ${responseServer.data.data.id}, share this link with your customer to complete payment:`);
      setModal(true);

      const response = responseServer.data;

    } catch (error: any) {
      setIsLoading(false);
      console.log("ERROR");
      setModalTitle(modalText[1].modalTitle);
      setModalBody(modalText[1].modalBody);
      setModal(true);
      return;
    }
  };

  const updateScheduleService = async (data: any, headers: any) => {
    try {
      const responseServer = await server.post(
        "/company/open-charges/update-company-service/" + ticketId,
        JSON.stringify(data),
        { headers: headers }
      );

      if (responseServer.data.statusCode !== 200) {
        return;
      }

      setModalTitle(modalText[0].modalBody);
      const strLink = `${UPKEEP_DOMAIN}/ScheduledTicket/${responseServer.data.data.id}/${responseServer.data.data.ticketGUID}`;
      setGeneretedUrl(strLink);
      setModalBody(`You have schedule ticket ${responseServer.data.data.id}, share this link with your customer to complete payment:`);
      setModal(true);

      const response = responseServer.data;

    } catch (error: any) {
      setIsLoading(false);
      setModalTitle(modalText[1].modalTitle);
      setModalBody(modalText[1].modalBody);
      setModal(true);
      return;
    }
  };

  const onChangeModal = (value: any) => {
    history.goBack();
    setModal(value);
  };


  const onClickClipboard = (e: any) => {
    navigator.clipboard.writeText(generetedUrl);
    setButtonCopiedText("Copied!");
    setTimeout(() => {
      setButtonCopiedText("Copy");
    }, 4000);
  };

  const handleSelect =
    ({ description }) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            //console.log("Coordinates: ", { lat, lng });
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setAddress(e.target.value);
    setIsUpdate(false);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const handlePhoneChange = (evt) => {
    const number = evt.target.validity.valid
      ? evt.target.value
      : phone;
    setPhone(number);
  };

  const handleZipCodeChange = (evt) => {
    const number = evt.target.validity.valid
      ? evt.target.value
      : zipCode;
    setZipCode(number);
  };


  const findTicket = async (ticketID: any) => {
    setIsLoading(true);

    const headers = {
      'Authorization': `Bearer ${state.token}`
    };

    try {
      const responseServer = await server.get(`/company/tickets/${ticketID}`,
        { headers: headers });

      if (responseServer.data.statusCode !== 200) {
        setIsLoading(false);
        console.log(responseServer.data.message);
        return;
      }

      const response = responseServer.data;

      setName(response.data.name);
      setEmail(response.data.email);
      setPhone(response.data.phoneNumber);
      setZipCode(response.data.zipCode.zipCode);
      setValue(response.data.address);
      setAddress(response.data.address);
      handleSelect(response.data.address);
      setScheduleDate(response.data.scheduledDate);
      setServiceId(response.data.servicesID);
      findCompanyServiceId(response.data.servicesID, response.data.scheduledDate);

      //get all services
      try {
        const servicesResponse = await server.get(`/company/open-charges/ticket/${ticketID}`,
          { headers: headers });

        if (servicesResponse.data.statusCode !== 200) {
          setIsLoading(false);
          console.log(servicesResponse.data.message);
          return;
        }

        const servicesListByTicket = servicesResponse.data.data;

        servicesListByTicket.forEach(item => {
          setServicesList(servicesList => [...servicesList, {
            description: item.description,
            price: item.price,
            duration: item.duration,
          }]);
        });
        setCanSubmit(true);
      } catch (error: any) {
        setIsLoading(false);
        console.log("Error while getting services list.");
        return;
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR");
      return;
    }
  };

  const onChangeDatePicker = (value) => {
    setScheduleDate(value);
    requestTimes(companyServiceId, value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Modal isOpen={modal} toggle={onChangeModal} className="width-modal">
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <span dangerouslySetInnerHTML={{ __html: modalBody }} />
          {
            generetedUrl !== "" ?
              <div className="body-modal-container">
                <input className="link-input-modal" readOnly value={generetedUrl} />
                <Button className="width-modal-btn" color="secondary" onClick={onClickClipboard}>
                  {buttonCopiedText}
                </Button>
              </div>
              : null
          }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onChangeModal}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <MyTitle title={viewTitle} />
      <div className="schedule-service-container">
        <div className="schedule-personal-info-container">
          <div className="titles">
            <label className="address-label">Personal Info:</label>
          </div>
          <Form>
            <div className="contact-inputs-schedule-service">
              <div className="contact-inputs-column">
                <FormGroup>
                  <Input
                    value={name} type="text" name="text"
                    className="form-input-payment-info" placeholder="Name"
                    onChange={e => setName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    value={email} type="email" name="email"
                    className="form-input-payment-info" placeholder="Email"
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="contact-inputs-column">
                <FormGroup>
                  <Input
                    value={phone != 0 ? phone : ""} type="text" name="number"
                    pattern="[0-9]*" autoComplete="off"
                    className="form-input-payment-info" placeholder="Phone"
                    onInput={handlePhoneChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    value={zipCode != 0 ? zipCode : ""} type="text" name="number"
                    pattern="[0-9]*" autoComplete="off"
                    className="form-input-payment-info" placeholder="Zip Code"
                    onInput={handleZipCodeChange}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="google-adress-input" ref={ref}>
              <label className="address-label">Servicing Address</label>
              <input
                className="form-input-payment-info google-input"
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Servicing address"
              />

              {/* We can use the "status" to decide whether we should display the dropdown or not */}
              {status === "OK" && !isUpdate && <ul>{renderSuggestions()}</ul>}
              <label className="label-zip-code-warning">
                Address needs to match zip code entered. Failed to do so will result in service not being performed.
              </label>
            </div>
          </Form>
          <div className="add-schedule-service-container">
            {
              !isUpdateTicket ?
                <BtnAddList label="Add Service" onClick={addService} />
                : null
            }
            {
              isUpdateTicket ?
                <div className="calendar-schedule-service-container">
                  <p>Schedule Date:</p>
                  <DatePicker
                    placeholderText={"Start Date"}
                    onChange={onChangeDatePicker}
                    className="calendar-schedule-service"
                    dateFormat="EEE, MMM dd yyyy"
                    selected={new Date(scheduleDate)}
                    minDate={new Date()}
                  />
                </div>
                : null
            }
          </div>
        </div>
      </div>
      <div className="new-open-charge-container">
        {
          servicesList.map((item, index) => (
            <CreateScheduleService
              key={index}
              id={index}
              description={item.description}
              price={item.price}
              duration={item.duration}
              removeService={e => removeService(index)}
              onChangeDescription={e => handleChangeDescription(index, e)}
              onChangePrice={e => handleChangePrice(index, e)}
              onChangeServiceDuration={e => handleScheduledServiceOnChangeduration(index, e)}
              allDurations={allDurations}
              disabled={isUpdateTicket}
            />
          ))
        }
      </div>
      {(allTimes.length > 0) ?
        <div className="datetime-open-charge">
          <div className="container-hour-open-charge">
            <label className="label-text">Time:</label>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} direction="down">
              <DropdownToggle caret className="dropdown-schedule-service">
                {selectedTime}
              </DropdownToggle>
              <DropdownMenu>
                {allTimes.map((item: any, index: number) => {
                  return (
                    <DropdownItem
                      onClick={() => onChangeSelectTime(item)}
                      key={index}
                    >
                      {item.startTime}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        : <div className="schedule-serv-no-available-items-container">
            <p>No available times on selected date!</p>
          </div>
      }

      <div className="submit-open-charge">
        <Button
          type="submit"
          className="btn-open-charge"
          disabled={servicesList.length === 0 || !canSubmit}
          onClick={handleSubmit}
        >
          SUBMIT
        </Button>
      </div>
    </>
  );
}

export default ScheduleService;
