import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./MyDatePicker.css";

const MyDatePicker: React.FC<any> = ({
  setDatePicker,
  dateDefault,
  disabled = false,
  isMinDate = false,
}) => {
  const [startDate, setStartDate] = useState(dateDefault);

  const CustomInput = React.forwardRef(({ value, onClick }: any, ref) => (
    <div className="custom-input-calendar">
      <input
        type="text"
        name=""
        id=""
        readOnly
        onClick={onClick}
        className="input-calendar"
        value={value}
        disabled={disabled}
      />
      <img
        src="assets/calendar.svg"
        alt="icon calendar"
        className="img-calendar"
        onClick={onClick}
      />
    </div>
  ));

  useEffect(() => {
    if (dateDefault === "") {
      setStartDate(new Date());
      setDatePicker(new Date());
    } else {
      setStartDate(dateDefault);
      setDatePicker(dateDefault);
    }
  }, [dateDefault]);
  return (
    <>
      <div className="customDatePickerWidth">
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            setDatePicker(date);
          }}
          dateFormat="EEE, MMM dd yyyy"
          customInput={<CustomInput />}
          disabled={disabled}
          minDate={isMinDate ? new Date() : null}
        />
      </div>
    </>
  );
};

export default MyDatePicker;
