import React, { createContext, useReducer, useEffect } from "react";
import { useLocalStorage } from './useLocalStoraje';

let AppContext = createContext();

const initialState = {
  isLoggedin: false,
  token: undefined,
  user: undefined,
  hasRequiredRegister: false,
  isPreviousLoggedIn: false,
  userSaved: undefined,
  registerEmail: undefined,
  existTicketId: false,
  ticketID: undefined,
  ticketListSent: false,
  existPaymentIntent: false,
  clientSecret: undefined,
  existPayload: false,
  paymentError: false,
  paymentErrorMessage: undefined,
  cardElement: undefined,
  notification: false,
  isMobile: false,
  permissions: [],
  isTempPassword: false,
}

let reducer = (state, action) => {
  switch (action.type) {
    case 'set-is-loggedin':
      return { ...state, isLoggedin: action.isLoggedin };
    case 'set-token':
      return { ...state, token: action.token };
    case 'set-is-previous-open':
      return { ...state, isPreviousLoggedIn: action.isPreviousLoggedIn };
    case 'set-has-required-register':
      return { ...state, hasRequiredRegister: action.hasRequiredRegister };
    case 'set-user-saved':
      return { ...state, userSaved: action.userSaved };
    case 'set-user':
      return { ...state, user: action.user };
    case 'set-register-email':
      return { ...state, registerEmail: action.registerEmail };
    case 'set-exist-ticket-id':
      return { ...state, existTicketId: action.existTicketId };
    case 'set-ticket-id':
      return { ...state, ticketID: action.ticketID };
    case 'set-ticket-list-sent':
      return { ...state, ticketListSent: action.ticketListSent };
    case 'set-exist-payment-intent':
      return { ...state, existPaymentIntent: action.existPaymentIntent };
    case 'set-client-secret':
      return { ...state, clientSecret: action.clientSecret };
    case 'set-exist-payload':
      return { ...state, existPayload: action.existPayload };
    case 'set-payment-error':
      return { ...state, paymentError: action.paymentError };
    case 'set-payment-error-message':
      return { ...state, paymentErrorMessage: action.paymentErrorMessage };
    case 'set-card-element':
      return { ...state, cardElement: action.cardElement };
    case 'set-notification':
      return { ...state, notification: action.notification };
    case 'set-is-mobile':
      return { ...state, isMobile: action.isMobile };
    case "set-permissions":
      return {...state, permissions: action.permissions}
    case 'set-temp-password':
     return { ...state, isTempPassword: action.isTempPassword };
    default:
      return state;
  }
};

function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState
  }

  let [data, setData] = useLocalStorage('upkeep-company', fullInitialState);
  let [state, dispatch] = useReducer(reducer, data);
  let value = { state, dispatch };

  useEffect(() => {
    setData(state);
  }, [state, setData]);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
